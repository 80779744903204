// src/features/authSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  user: any;
  currentUrl?: string;
  membership?: string;
}

// Load the authentication state from localStorage, only if available
let initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  currentUrl: "",
  membership: "BASIC",
};

if (typeof window !== "undefined") {
  const savedAuthState = localStorage.getItem("authState");
  if (savedAuthState) {
    initialState = savedAuthState ? JSON.parse(savedAuthState) : initialState;
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.membership = action.payload.entitlement;

      // Save the authentication state to localStorage
      if (typeof window !== "undefined") {
        localStorage.setItem("authState", JSON.stringify(state));
      }
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.currentUrl = "";
      state.membership = "BASIC";

      // Remove the authentication state from localStorage
      if (typeof window !== "undefined") {
        localStorage.removeItem("authState");        
      }
    },
    storeCurrentUrl(state, action: PayloadAction<string>) {
      state.currentUrl = action.payload;
    },
    updateMembership(state, action: PayloadAction<string>) {
      state.membership = action.payload;

      // Save the updated state to localStorage
      if (typeof window !== "undefined") {
        localStorage.setItem("authState", JSON.stringify(state));
      }
    },
  },
});

export const { login, logout, storeCurrentUrl, updateMembership } = authSlice.actions;
export default authSlice.reducer;
