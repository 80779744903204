// src/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import loginPopupSlice from './features/loginPopupSlice';
import proPopupSlice from './features/proPopupSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        loginPopup: loginPopupSlice,
        proPopup: proPopupSlice,
    },
});
