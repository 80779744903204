// src/features/proPopupSlice.ts
import { createSlice } from '@reduxjs/toolkit';

const proPopupSlice = createSlice({
    name: 'proPopup',
    initialState: { show: false },
    reducers: {
        showProPopup: state => {
            state.show = true;
        },
        hideProPopup: state => {
            state.show = false;
        },
    },
});

export const { showProPopup, hideProPopup } = proPopupSlice.actions;

export default proPopupSlice.reducer;