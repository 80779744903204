// src/features/loginPopupSlice.ts
import { createSlice } from '@reduxjs/toolkit';

const loginPopupSlice = createSlice({
  name: 'loginPopup',
  initialState: { show: false },
  reducers: {
    showLoginPopup: state => {
      state.show = true;
    },
    hideLoginPopup: state => {
      state.show = false;
    },
  },
});

export const { showLoginPopup, hideLoginPopup } = loginPopupSlice.actions;

export default loginPopupSlice.reducer;